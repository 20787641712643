.lg-backdrop {
  z-index: 11040;
}

.lg-outer {
  z-index: 11050;
}

.lg-outer .lg-item.lg-current {
  z-index: 11060;
}

.lg-actions .lg-next, .lg-actions .lg-prev, .lg-sub-html, .lg-outer .lg-thumb-outer, .lg-outer .lg-video .lg-video-play, .lg-outer .lg-pager-outer {
  z-index: 11080;
}

.lg-outer #lg-dropdown-overlay {
  z-index: 11081;
}

.lg-toolbar {
  z-index: 11082;
}

.lg-progress-bar {
  z-index: 11083;
}
