@font-face {
  font-family:SBSans;
  font-weight:400;
  src: url(../../../_core/assets/fonts/SBSans/SBSansText.ttf) format("truetype")
}
@font-face {
  font-family:SBSans;
  font-weight:600;
  src: url(../../../_core/assets/fonts/SBSans/SBSansText-Medium.ttf) format("truetype")
}
@font-face {
  font-family:SBSans;
  font-weight:700;
  src: url(../../../_core/assets/fonts/SBSans/SBSansText-Semibold.ttf) format("truetype")
}
@font-face {
  font-family:SBSans;
  font-weight:900;
  src: url(../../../_core/assets/fonts/SBSans/SBSansText-Bold.ttf) format("truetype")
}

// Display
@font-face {
  font-family:SBSansDisplay;
  font-weight:400;
  src: url(../../../_core/assets/fonts/SBSans/Display/SBSansDisplay.otf) format("opentype")
}
@font-face {
  font-family:SBSansDisplay;
  font-weight:700;
  src: url(../../../_core/assets/fonts/SBSans/Display/SBSansDisplay-Bold.otf) format("opentype")
}

// Interface
@font-face {
  font-family:SBSansInterface;
  font-weight:400;
  src: url(../../../_core/assets/fonts/SBSans/Interface/SBSansInterface.otf) format("opentype")
}
@font-face {
  font-family:SBSansInterface;
  font-weight:300;
  src: url(../../../_core/assets/fonts/SBSans/Interface/SBSansInterface-Light.otf) format("opentype")
}
@font-face {
  font-family:SBSansInterface;
  font-weight:700;
  src: url(../../../_core/assets/fonts/SBSans/Interface/SBSansInterface-Semibold.otf) format("opentype")
}
@font-face {
  font-family:SBSansInterface;
  font-weight:900;
  src: url(../../../_core/assets/fonts/SBSans/Interface/SBSansInterface-Bold.otf) format("opentype")
}
