html,
body,
main,
#app {
  width: 100%;
  height: 100%;
}
.graphStyle {
  .ct-bar:nth-child(1) {
    stroke: #ce2949;
  }
  .ct-bar:nth-child(2) {
    stroke: #f89e4c;
  }
  .ct-bar:nth-child(3) {
    stroke: #f36e38;
  }
  .ct-bar:nth-child(4) {
    stroke: #ef4648;
  }
  .ct-bar:nth-child(5) {
    stroke: #582841;
  }
  .ct-bar:nth-child(6) {
    stroke: #ce2949;
  }
  .ct-bar:nth-child(7) {
    stroke: #f89e4c;
  }
  .ct-bar:nth-child(8) {
    stroke: #f36e38;
  }
  .ct-bar:nth-child(9) {
    stroke: #ef4648;
  }
  .ct-bar:nth-child(10) {
    stroke: #582841;
  }
  .ct-bar:nth-child(11) {
    stroke: #ce2949;
  }
  .ct-bar:nth-child(12) {
    stroke: #f89e4c;
  }
  .ct-bar:nth-child(13) {
    stroke: #f36e38;
  }
  .ct-bar:nth-child(14) {
    stroke: #ef4648;
  }
  .ct-bar:nth-child(15) {
    stroke: #582841;
  }
  .ct-bar:nth-child(16) {
    stroke: #ce2949;
  }
  .ct-bar:nth-child(17) {
    stroke: #f89e4c;
  }
  .ct-bar:nth-child(18) {
    stroke: #f36e38;
  }
  .ct-bar:nth-child(19) {
    stroke: #ef4648;
  }
  .ct-bar:nth-child(20) {
    stroke: #582841;
  }
}
.ct-horizontal {
  height: unset !important;
  flex-direction: column !important;
  align-items: center !important;
}

.slick-list,
.slick-track {
  height: 100%;
}
.slick-track {
  display: flex !important;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
}
.slick-slide > div {
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance:textfield;
}