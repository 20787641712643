@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Bold.eot');
    src: local('Manrope Bold'), local('Manrope-Bold'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Bold.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Bold.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Semibold.eot');
    src: local('Manrope Semibold'), local('Manrope-Semibold'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Semibold.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Semibold.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Regular.eot');
    src: local('Manrope Regular'), local('Manrope-Regular'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Regular.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Regular.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Light.eot');
    src: local('Manrope Light'), local('Manrope-Light'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Light.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Light.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Light.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Thin.eot');
    src: local('Manrope Thin'), local('Manrope-Thin'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Thin.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Thin.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Thin.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-Medium.eot');
    src: local('Manrope Medium'), local('Manrope-Medium'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Medium.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Medium.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Manrope';
    src: url('../../../_core/assets/fonts/Manrope/Manrope-ExtraBold.eot');
    src: local('Manrope ExtraBold'), local('Manrope-ExtraBold'),
        url('../../../_core/assets/fonts/Manrope/Manrope-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../../../_core/assets/fonts/Manrope/Manrope-ExtraBold.woff2') format('woff2'),
        url('../../../_core/assets/fonts/Manrope/Manrope-ExtraBold.woff') format('woff'),
        url('../../../_core/assets/fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
