$dark1: #1e1e1e;
$dark2: #252526;
$dark3: #333333;
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 4px 0 $dark3, 0 0 20px 20px $dark1;
//   border-radius: 10px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar {
//   width: 12px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 10px;
//   box-shadow: inset 0 0 6px $dark1;
//   background-color: #555;
// }

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 9px;
	height: 9px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #aaa;
}
body .modal-root--DIM5T {
    z-index: 99999;
}
